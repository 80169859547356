var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.pageLoaded)?_c('div',[_c('h1',{staticClass:"mt-4"},[_vm._v(_vm._s(_setup.t('title')))]),_c('p',{staticClass:"font-21"},[_vm._v(_vm._s(_setup.t('description')))]),_c(_setup.BaseAlert,{staticClass:"mb-4 p-3",attrs:{"variant":"warning","alternate":"","data-test-automation-id":_setup.getAutomationId('claim-history-alert-dont-see-claims')}},[_c('h2',{staticClass:"lato font-17 m-0 mb-1 line-normal"},[_vm._v(_vm._s(_setup.t('claimAlert.title')))]),_c('p',{staticClass:"lato font-17 font-light m-0"},[_vm._v(_vm._s(_setup.t('claimAlert.description')))])]),_c(_setup.BaseCollapse,{staticClass:"claim-history-filter mb-4",attrs:{"button-label":_setup.t('search'),"button-variant":"primary","icon-two":['fal', 'search'],"visible":!_setup.searchCollapsed,"automation-id":_setup.getAutomationId('history-base-collapse')},on:{"input":_setup.changeCollapse}},[_c(_setup.ClaimPaymentHistoryFilter,{attrs:{"participants":_setup.activeParticipants,"benefits":_setup.benefits,"initial-state":_setup.searchData},on:{"search":_setup.handleSearch}})],1),(_setup.history)?_c('div',{ref:"results"},[(_setup.history.length > 0 && _setup.showHistoryAlert)?_c(_setup.BaseAlert,{staticClass:"mb-4 p-3",attrs:{"variant":"warning","alternate":"","data-test-automation-id":_setup.getAutomationId('claim-history-alert-statement')}},[_c('p',{staticClass:"lato font-17 font-light m-0"},[_vm._v(_vm._s(_setup.t('statementAlert')))])]):_vm._e(),_vm._l((_setup.history),function(entry,index){return _c(_setup.HistoryTable,{key:index,staticClass:"mt-4",attrs:{"top-bar-items":_setup.topBarItems(entry.participantInfo),"history-fields":_setup.historyFields,"history-headers":_setup.historyHeaders(),"history":entry.claimServices,"show-totals":"","show-grand-totals":index === _setup.history.length - 1,"totals":[
				{ key: 'submittedAmount', value: entry.totalSubmittedAmount },
				{ key: 'paidAmount', value: entry.totalPaidAmount }
			],"grand-totals":[
				{ key: 'submittedAmount', value: _setup.grandTotalSubmitted },
				{ key: 'paidAmount', value: _setup.grandTotalPaid }
			]}})}),(_setup.history.length > 0)?_c(_setup.BaseButton,{staticClass:"mt-2 mb-4",attrs:{"label":_setup.t('downloadButton'),"variant":"primary","icon":['fal', 'print'],"icon-position":"left","automation-id":_setup.getAutomationId('claim-history-download-button')},on:{"click":function($event){return _setup.downloadPDF()}}}):_c('div',[_c('h2',{staticClass:"mt-4 font-21"},[_vm._v(" "+_vm._s(_setup.initialSearch ? _setup.t('noRecent.title') : _setup.t('noResults.title'))+" ")]),_c('p',{staticClass:"font-21"},[_vm._v(" "+_vm._s(_setup.initialSearch ? _setup.t('noRecent.description') : _setup.t('noResults.description'))+" ")])])],2):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }