<template>
	<div v-if="loaded">
		<h1 class="mt-4 mb-2">{{ t('claimStatement') }}</h1>

		<!-- can't use BaseTable because of tooltips -->
		<table
			class="table b-table table-sm b-table-stacked stacked-table m-0 table-payment-info"
			role="table"
			aria-busy="false"
			:data-test-automation-id="getAutomationId('payment-info')"
		>
			<caption class="top-bar px-3">
				<h2>{{ t('paymentInfo.paymentInfo') }}</h2>
			</caption>
			<tbody role="rowgroup" class="body pt-3">
				<tr v-for="(item, index) in paymentTableItems()" :key="item.key" role="row">
					<td
						:aria-colindex="index + 1"
						role="cell"
						class="px-3 py-2"
						:class="{ blue: item.key === 'paymentTotal' }"
					>
						<div class="d-flex w-100">
							<label class="josefin w-50 m-0 pr-2">
								{{ item.header }}
								<template v-if="item.key === 'dateProcessed'">
									<InfoModal
										class="d-inline"
										:automation-id="getAutomationId('date-processed-modal')"
										:button-padding="false"
										:modal-title="t('paymentInfo.dateProcessedModal.title')"
									>
										{{ t('paymentInfo.dateProcessedModal.text') }}
									</InfoModal>
								</template>
							</label>
							<div class="w-50 pl-2">
								<template v-if="item.key === 'payeeAddress'">
									<span class="address">{{ item.value }}</span>
								</template>
								<template v-else-if="item.key === 'paymentTotal'">
									<span class="josefin">{{ item.value }}</span>
								</template>
								<template v-else>
									{{ item.value }}
								</template>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<h2 class="mt-4 mb-2">{{ t('calcBenefits') }}</h2>

		<div
			v-for="(payment, paymentIndex) in paymentDetails.participantPayments"
			:key="payment.participantInfo.participantId"
			class="mt-4 mb-3"
		>
			<div class="d-flex justify-content-between align-items-baseline">
				<h3 class="font-24">{{ participantName(payment) }}</h3>
				<div>{{ t('dob') }} {{ formatDate(payment.participantInfo.dateOfBirth) }}</div>
			</div>

			<template v-for="(line, lineIndex) in payment.paymentLines">
				<HistoryTable
					:key="line.serviceInfo.code"
					class="history-table"
					:history-fields="historyFields"
					:totals="subtotals()"
					:grand-totals="grandTotals()"
					:history-headers="historyHeaders"
					:history="line.claimServices"
					:top-bar-items="[topBarItem(line.serviceInfo)]"
					:show-totals="
						lineIndex === payment.paymentLines.length - 1 &&
						paymentIndex === paymentDetails.participantPayments.length - 1
					"
					:show-grand-totals="
						lineIndex === payment.paymentLines.length - 1 &&
						paymentIndex === paymentDetails.participantPayments.length - 1
					"
					:show-eob-explanation="lineIndex === payment.paymentLines.length - 1"
					:eobs="eobList(payment.participantInfo.participantId)"
					stacked="md"
					:bottom-margin="false"
					:automation-id="`history-table-${lineIndex}`"
				/>
			</template>
		</div>
		<div class="d-flex align-content-center my-4">
			<BaseButton
				:label="t('backLink')"
				variant="link"
				:automation-id="getAutomationId('btn-back')"
				:icon-two="['fal', 'chevron-left']"
				icon-two-position="left"
				class="px-0 mr-3"
				:pill="false"
				@click="navigateBack()"
			/>
			<BaseButton
				:label="t('downloadButton')"
				variant="primary"
				:icon="['fal', 'print']"
				icon-position="left"
				:automation-id="getAutomationId('claim-history-download-button')"
				@click="downloadPDF()"
			/>
		</div>
		<div class="mb-4">
			<BaseButton
				:label="t('missingDetails.title')"
				variant="link"
				:automation-id="getAutomationId('something-missing')"
				:icon="['fal', missingOpen ? 'chevron-up' : 'chevron-down']"
				icon-position="right"
				class="px-0 mr-3"
				:pill="false"
				@click="missingOpen = !missingOpen"
			/>
			<div v-if="missingOpen">
				{{ t('missingDetails.text') }}
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, computed, watch } from 'vue';
import store from '@/store';
import { format, parseISO } from 'date-fns';
import { enCA, frCA } from 'date-fns/esm/locale';
import router from '@/router/index';
// components
import HistoryTable from '@/components/history/HistoryTable.vue';
import InfoModal from '@/components/common/InfoModal.vue';
// composables
import { useI18n } from '@/composables/useI18n';
import { useBreadcrumbs } from '@/composables/useBreadcrumbs';
import { useComponentId } from '@/composables/useComponentId';
// models
import PaymentDetails from '@/models/claims/PaymentDetails';
// routes
import { DASHBOARD, HISTORY } from '@/constants/Routes';
import BaseButton from '@/components/common/base/BaseButton.vue';
// utils
import { downloadBlob } from '@/utils/commonUtils';

const { t } = useI18n();
const root = getCurrentInstance().proxy.$root;
const { getAutomationId } = useComponentId();

// static
const { email, apiToken } = sessionStorage;
const historyFields = [
	'claimId',
	'serviceDate',
	'submittedAmount',
	'eligibleAmount',
	'excludedAmount',
	'coinsurancePercent',
	'paidAmount',
	'eobs'
];
const totalFields = ['submittedAmount', 'eligibleAmount', 'excludedAmount', 'paidAmount'];

// refs
const paymentDetails = ref(null);
const loaded = ref(false);
const missingOpen = ref(false);

watch(
	() => root.$i18n.locale,
	async () => {
		const hasDetails = await PaymentDetails.hasPaymentDetails(email, root.$i18n.locale, claimId);
		if (!hasDetails) {
			loaded.value = false;
			store.dispatch('updateLoading', true);
		}

		const claimId = root.$route.query['claim-id'];
		paymentDetails.value = await PaymentDetails.getPaymentDetails(
			email,
			apiToken,
			root.$i18n.locale,
			claimId
		);

		loaded.value = true;
		store.dispatch('updateLoading', false);
	}
);

// computed
const historyHeaders = computed(() => {
	return [
		{ key: 'claimId', label: t('historyHeaders.claimId') },
		{ key: 'serviceDate', label: t('historyHeaders.serviceDate') },
		{ key: 'submittedAmount', label: t('historyHeaders.submittedAmount') },
		{ key: 'eligibleAmount', label: t('historyHeaders.eligibleAmount') },
		{ key: 'excludedAmount', label: t('historyHeaders.excludedAmount') },
		{ key: 'coinsurancePercent', label: t('historyHeaders.coinsurancePercent') },
		{ key: 'paidAmount', label: t('historyHeaders.paidAmount') },
		{ key: 'eobs', label: t('historyHeaders.eob') }
	];
});

// methods
function topBarItem(serviceInfo) {
	return Object.values(serviceInfo)
		.map((item) => item)
		.join(' - ');
}
function participantName(payment) {
	const capitalize = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
	return `${capitalize(payment.participantInfo.firstName)} ${capitalize(
		payment.participantInfo.lastName
	)}`;
}
function paymentTableItems() {
	const items = Object.entries(paymentDetails.value.paymentInfo).map((item) => {
		switch (item[0]) {
			case 'dateProcessed':
				return {
					key: item[0],
					header: t('paymentInfo.dateProcessed'),
					value: formatDate(item[1]),
					order: 0
				};
			case 'payeeName':
				return { key: item[0], header: t('paymentInfo.payeeName'), value: item[1], order: 1 };
			case 'paymentMethod':
				return { key: item[0], header: t('paymentInfo.paymentMethod'), value: item[1], order: 2 };
			case 'payeeAddress':
				return {
					key: item[0],
					header: t('paymentInfo.payeeAddress'),
					value: formattedAddress(item[1]),
					order: 3
				};
		}
	});
	items.push({
		key: 'paymentTotal',
		header: t('paymentInfo.paymentAmount'),
		value: formatCurrency(paymentDetails.value.totalPaidAmount),
		order: 4
	});
	return items.sort((a, b) => (b.order < a.order ? 1 : -1));
}
function formatCurrency(number) {
	return number.toLocaleString(root.$i18n.locale === 'fr' ? 'fr-CA' : 'en-CA', {
		style: 'currency',
		currency: 'CAD'
	});
}
function formatDate(date) {
	return format(new Date(parseISO(date)), 'dd MMM yyyy', {
		locale: root.$i18n.locale === 'fr' ? frCA : enCA
	});
}
function formattedAddress(address) {
	return `${address.addressLine1}\n${address.city}, ${address.province}\n${address.postalCode}`;
}
function eobList(participantId) {
	const eobs = [];
	const participantClaims = paymentDetails.value.participantPayments.find(
		(payments) => payments.participantInfo.participantId === participantId
	);
	participantClaims.paymentLines.forEach((paymentLine) => {
		paymentLine.claimServices.forEach((claimService) => {
			claimService.eobs?.forEach((eob) => {
				if (!eobs.find((item) => item.eobCode === eob.eobCode)) {
					eobs.push(eob);
				}
			});
		});
	});
	return eobs.sort((a, b) => (a.eobCode < b.eobCode ? -1 : 1));
}
function subtotals() {
	return totalFields.map((field) => ({
		key: field,
		value: paymentDetails.value[`total${field.charAt(0).toUpperCase()}${field.slice(1)}`]
	}));
}
function grandTotals() {
	return [{ key: 'paidAmount', value: paymentDetails.value.totalPaidAmount }];
}
function navigateBack() {
	router.push({ name: HISTORY });
}
async function downloadPDF() {
	store.dispatch('updateLoading', true);

	const claimId = root.$route.query['claim-id'];

	await PaymentDetails.getPaymentDetailsPDF(email, apiToken, root.$i18n.locale, claimId).then(
		(myBlob) => {
			downloadBlob(
				myBlob,
				root.$i18n.locale === 'fr'
					? `détails-sur-le-paiement-${claimId}.pdf`
					: `payment-details-claim-${claimId}.pdf`
			);
		}
	);

	store.dispatch('updateLoading', false);
}

// lifecycle methods
onMounted(async () => {
	const claimId = root.$route.query['claim-id'];
	const hasDetails = await PaymentDetails.hasPaymentDetails(email, root.$i18n.locale, claimId);

	if (!hasDetails) {
		store.dispatch('updateLoading', true);
		loaded.value = false;
	}

	paymentDetails.value = await PaymentDetails.getPaymentDetails(
		email,
		apiToken,
		root.$i18n.locale,
		claimId
	);

	useBreadcrumbs(
		[
			{ text: t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
			{ text: t('breadcrumb.claimHistory', 'en'), to: { name: HISTORY } },
			{ text: t('breadcrumb.details', 'en') }
		],
		[
			{ text: t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
			{ text: t('breadcrumb.claimHistory', 'fr'), to: { name: HISTORY } },
			{ text: t('breadcrumb.details', 'fr') }
		]
	);

	loaded.value = true;
	store.dispatch('updateLoading', false);
});
</script>

<style lang="scss" scoped>
::v-deep .table-payment-info {
	max-width: 540px;
	& caption {
		padding: 0;
	}
	& .top-bar {
		background-color: $gray-light;
		border-radius: 5px 5px 0px 0px;
		height: 100%;
		color: $black;
		padding: 15px;
		& h2 {
			font-size: 24px;
			font-family: $josefin-font-family;
			margin: 0;
			line-height: normal;
		}
	}
	& .body {
		background-color: $white;
		border-left: 1px solid $gray-light;
		border-right: 1px solid $gray-light;
		& td {
			border-top: none;
			&.blue {
				color: $white;
				background-color: $blue-dark;
				border-radius: 0px 0px 5px 5px;
			}
			& .josefin {
				font-family: $josefin-font-family;
			}
			& .address {
				white-space: pre-line;
			}
		}
	}
}
::v-deep .history-table {
	& caption div div {
		font-size: 18px !important;
	}
}
</style>
<i18n lang="json">
{
	"en": {
		"claimStatement": "Claim Statement",
		"calcBenefits": "Calculation of Benefits",
		"dob": "Date of Birth: ",
		"downloadButton": "Download",
		"backLink": "Back to results",
		"missingDetails": {
			"title": "Something missing from your claim details?",
			"text": "Not all reductions to claim payouts are displayed. Sometimes your claim payments might be reduced based on things like eligible expenses, coordination of benefits (payouts to your spouse's plan) or payment made directly to your provider."
		},
		"paymentInfo": {
			"dateProcessed": "Date Processed",
			"paymentMethod": "Payment Type",
			"payeeName": "Paid to",
			"payeeAddress": "Sent to",
			"paymentInfo": "Payment Information",
			"paymentAmount": "Payment Amount",
			"dateProcessedModal": {
				"title": "What does this mean?",
				"text": "\"Date processed\" refers to the date that we processed your submitted claim and issued your payment. If you've chosen to be reimbursed through direct deposit, it can take up to 7 business days for your bank to deposit your money."
			}
		},
		"historyHeaders": {
			"claimId": "Claim ID",
			"serviceDate": "Service Date",
			"submittedAmount": "Submitted Amount",
			"eligibleAmount": "Eligible Amount",
			"excludedAmount": "Excluded Amount",
			"coinsurancePercent": "Percentage Covered",
			"paidAmount": "Blue Cross Paid",
			"eob": "EOB"
		},
		"breadcrumb": {
			"home": "Home",
			"claimHistory": "Claims History",
			"details": "Claim Statement"
		}
	},
	"fr": {
		"claimStatement": "Relevé de demande de règlement",
		"calcBenefits": "Calcul des prestations",
		"dob": "Date de naissance: ",
		"downloadButton": "Télécharger",
		"backLink": "Retour aux résultats",
		"missingDetails": {
			"title": "Il manque quelque chose dans les détails de votre demande de règlement?",
			"text": "Les réductions aux paiements des demandes de règlement ne sont pas toutes affichées. Parfois, le paiement de votre demande est réduit en fonction d'éléments tels que les dépenses admissibles, la coordination des prestations (paiements faits au régime de votre conjoint ou conjointe) ou le paiement effectués directement à votre fournisseur."
		},
		"paymentInfo": {
			"dateProcessed": "Date de traitement",
			"paymentMethod": "Type de paiement",
			"payeeName": "Payé à",
			"payeeAddress": "Envoyer à",
			"paymentInfo": "Renseignements sur le paiement",
			"paymentAmount": "Montant du paiement",
			"dateProcessedModal": {
				"title": "Qu'est-ce que cela signifie?",
				"text": "La « date de traitement » est la date à laquelle nous avons traité votre demande de règlement et émis votre paiement. Si vous avez opté pour le remboursement par dépôt direct, il faut compter jusqu'à 7 jours ouvrables pour que votre banque dépose votre argent."
			}
		},
		"historyHeaders": {
			"claimId": "ID demande règlement",
			"serviceDate": "Date de service",
			"submittedAmount": "Montant soumis",
			"eligibleAmount": "Montant admissible",
			"excludedAmount": "Montant exclu",
			"coinsurancePercent": "Pourcentage couvert",
			"paidAmount": "Croix Bleue a payé",
			"eob": "Explication"
		},
		"breadcrumb": {
			"home": "Accueil",
			"claimHistory": "Historique des demandes de règlement",
			"details": "Relevé de demande de règlement"
		}
	}
}
</i18n>
