<template>
	<BaseForm v-slot="{ invalid }" class="p-3">
		<BRow>
			<BCol>
				<BaseRadioButtonGroup
					:value="searchType"
					label-font-size="21"
					class="rdo-search-type"
					:options="getSearchTypes()"
					:label="t('label')"
					:automation-id="getAutomationId('rdo-searchtype')"
					@input="updateSearchType"
				/>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="12" sm="6">
				<BaseDatePicker
					automation-id="claim-payment-history-filter-dte-from-date"
					:min-error-date="dateRange.fromDate"
					:max-error-date="dateRange.toDate"
					:min-selectable-date="dateRange.fromDate"
					:max-selectable-date="toDate"
					:value="fromDate"
					:label="t('startDate')"
					required
					:required-error-msg="t('error.fromdateRequired')"
					:after-min-date-error-msg="t('error.beforeTodayAndAfterMinDateError')"
					:before-max-date-error-msg="t('error.maxDateFrom')"
					@input="updateFromDate"
				>
					<template #suffix>
						<span>{{ t('dateSuffix') }}</span>
					</template>
				</BaseDatePicker>
			</BCol>
			<BCol cols="12" sm="6">
				<BaseDatePicker
					automation-id="claim-payment-history-filter-dte-to-date"
					:from-date="fromDate"
					:value="toDate"
					:label="t('endDate')"
					required
					:min-error-date="fromDate"
					:max-error-date="dateRange.toDate"
					:min-selectable-date="fromDate"
					:max-selectable-date="dateRange.toDate"
					:required-error-msg="t('error.todateRequired')"
					:after-min-date-error-msg="t('error.beforeTodayAndAfterMinDateError')"
					:before-max-date-error-msg="t('error.maxDate')"
					@input="updateToDate"
				>
					<template #suffix>
						<span>{{ t('dateSuffix') }}</span>
					</template>
				</BaseDatePicker>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="6">
				<label>{{ t('who') }}</label>
			</BCol>
			<BCol cols="6">
				<label>{{ t('benefitCategory') }}</label>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="6" class="d-flex flex-column">
				<ParticipantDropdown
					class="flex-grow-1"
					include-all
					:participants="participants"
					:selected-participant="selectedParticipant"
					variant="secondary"
					@click="updateSelectedParticipant"
			/></BCol>
			<BCol cols="6" class="d-flex flex-column">
				<BaseDropDown
					:automation-id="getAutomationId('claim-payment-history-filter-benefit-dropdown')"
					variant="secondary"
					class="flex-grow-1"
					caret-style="solid"
					float-right
					:title="benefits.find((benefit) => benefit.value === selectedBenefit).text"
				>
					<template #default="{ variant }">
						<BaseDropDownItem
							v-for="(benefit, index) in benefits"
							:id="`${getUniqueId('benefit-dropdown-item')}-${index}`"
							:key="benefit.value"
							:automation-id="getAutomationId(`benefit-dropdown-item-${index}`)"
							:active="benefit.value === selectedBenefit"
							:variant="variant"
							:value="benefit.value"
							:label="benefit.text"
							icon-active="check"
							icon-active-size="lg"
							@click="updateSelectedBenefit(benefit)"
						/>
					</template>
				</BaseDropDown>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<BaseButton
					:automation-id="getAutomationId('claim-payment-history-filter-btn-search')"
					class="mt-4"
					variant="primary"
					:label="t('search')"
					:disabled="invalid"
					@click="emitSearch"
				/>
			</BCol>
		</BRow>
	</BaseForm>
</template>

<script setup>
import { defineProps, defineEmits, watch, toRefs } from 'vue';
import { BRow, BCol } from 'bootstrap-vue';
import { format, subMonths, setDate } from 'date-fns';

// composables
import { useI18n } from '@/composables/useI18n';
import { useComponentId } from '@/composables/useComponentId';
// components
import BaseRadioButtonGroup from '@/components/common/base/BaseRadioButtonGroup';
import BaseDatePicker from '@/components/common/base/BaseDatePicker.vue';
import ParticipantDropdown from '@/components/common/ParticipantDropdown.vue';
import BaseDropDown from '@/components/common/base/BaseDropDown.vue';
import BaseDropDownItem from '@/components/common/base/BaseDropDownItem.vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseForm from '@/components/common/base/BaseForm.vue';

const { t } = useI18n();
const { getUniqueId, getAutomationId } = useComponentId();

watch(
	() => props.benefits,
	() =>
		(selectedBenefit.value = props.benefits.find((b) => b.value === selectedBenefit.value).value)
);

// props
const props = defineProps({
	participants: {
		type: Array,
		required: true
	},
	benefits: {
		type: Array,
		required: true
	},
	initialState: {
		type: Object,
		default: null
	}
});
const emit = defineEmits(['search']);

// static
const searchTypes = { dateProcessed: 'processed', serviceDate: 'service' };
const dateRange = {
	fromDate: format(new Date(1900, 0, 1), 'yyyy-MM-dd'),
	toDate: format(new Date(), 'yyyy-MM-dd')
};

// refs
const { searchType, fromDate, toDate, selectedParticipant, selectedBenefit } = toRefs(
	props.initialState || {
		searchType: searchTypes.dateProcessed,
		fromDate: getInitialDate(),
		toDate: dateRange.toDate,
		selectedParticipant: { participantId: 'all' },
		selectedBenefit: props.benefits[0].value
	}
);

// methods
function getSearchTypes() {
	return [
		{
			value: searchTypes.dateProcessed,
			text: t('dateProcessed'),
			modal: { title: t('modal.title'), text: t('modal.text') }
		},
		{ value: searchTypes.serviceDate, text: t('serviceDate') }
	];
}
function getInitialDate() {
	return format(subMonths(setDate(new Date(), 1), 6), 'yyyy-MM-dd');
}
function updateSearchType(value) {
	searchType.value = value;
}
function updateSelectedParticipant(participant) {
	selectedParticipant.value = participant.participant;
}
function updateSelectedBenefit(benefit) {
	selectedBenefit.value = benefit.value;
}
function updateFromDate(date) {
	fromDate.value = date ? date : getInitialDate();
}
function updateToDate(date) {
	toDate.value = date ? date : dateRange.toDate;
}
function emitSearch() {
	emit('search', {
		searchType: searchType.value,
		fromDate: fromDate.value,
		toDate: toDate.value,
		selectedParticipant: selectedParticipant.value,
		selectedBenefit: selectedBenefit.value
	});
}
</script>

<style lang="scss" scoped>
label {
	font-family: $josefin-font-family;
	font-size: 18px;
}
::v-deep .rdo-search-type {
	& label {
		font-size: 21px;
	}
	& .custom-control-label {
		font-size: 17px;
		& span {
			vertical-align: middle;
		}
	}
}
</style>

<i18n lang="json">
{
	"en": {
		"label": "Search by",
		"dateProcessed": "Date processed",
		"serviceDate": "Service date",
		"startDate": "Start date",
		"endDate": "End date",
		"dateSuffix": "(DD / MM / YYYY)",
		"who": "Who is it for?",
		"benefitCategory": "Benefit category",
		"search": "Search",
		"modal": {
			"title": "\"Date processed\" versus \"service date\"",
			"text": "\"Date processed\" refers to the date that we processed your claim and issued your payment. \"Service date\" is the date when you received the service in the claim."
		},
		"error": {
			"beforeTodayAndAfterMinDateError": "This date is too far in the past. Our search results are limited to 24 months of history.",
			"fromdateRequired": "Enter a start date for your search.",
			"todateRequired": "Enter an end date for your search.",
			"maxDateFrom": "The date you've entered is in the future. Please check the date and try again.",
			"maxDate": "The date you've entered is in the future. Please check the date and try again."
		}
	},
	"fr": {
		"label": "Rechercher par",
		"dateProcessed": "Date de traitement",
		"serviceDate": "Date du service",
		"startDate": "Date de début",
		"endDate": "Date de fin",
		"dateSuffix": "(JJ/MM/AAAA)",
		"who": "Qui fait l'objet de la demande?",
		"benefitCategory": "Catégorie de garantie",
		"search": "Rechercher",
		"modal": {
			"title": "Différence entre la « date de traitement » et la « date du service »",
			"text": "La « date de traitement » est la date à laquelle nous avons traité votre demande de règlement et émis votre paiement. La « date du service » est la date à laquelle vous avez reçu le service indiqué dans la demande de règlement."
		},
		"error": {
			"beforeTodayAndAfterMinDateError": "La date est trop éloignée dans le passé. La période de recherche est limitée aux 24 derniers mois de l'historique.",
			"fromdateRequired": "Entrez une date de début pour votre recherche.",
			"todateRequired": "Entrez une date de fin pour votre recherche.",
			"maxDateFrom": "La date que vous avez entrée est ultérieure à la date d'aujourd'hui. Veuillez corriger la date et essayer à nouveau.",
			"maxDate": "La date que vous avez entrée est ultérieure à la date d'aujourd'hui. Veuillez corriger la date et essayer à nouveau."
		}
	}
}
</i18n>
