<template>
	<div v-if="pageLoaded">
		<h1 class="mt-4">{{ t('title') }}</h1>
		<p class="font-21">{{ t('description') }}</p>
		<BaseAlert
			class="mb-4 p-3"
			variant="warning"
			alternate
			:data-test-automation-id="getAutomationId('claim-history-alert-dont-see-claims')"
		>
			<h2 class="lato font-17 m-0 mb-1 line-normal">{{ t('claimAlert.title') }}</h2>
			<p class="lato font-17 font-light m-0">{{ t('claimAlert.description') }}</p>
		</BaseAlert>
		<BaseCollapse
			:button-label="t('search')"
			button-variant="primary"
			:icon-two="['fal', 'search']"
			:visible="!searchCollapsed"
			:automation-id="getAutomationId('history-base-collapse')"
			class="claim-history-filter mb-4"
			@input="changeCollapse"
		>
			<ClaimPaymentHistoryFilter
				:participants="activeParticipants"
				:benefits="benefits"
				:initial-state="searchData"
				@search="handleSearch"
			/>
		</BaseCollapse>
		<div v-if="history" ref="results">
			<BaseAlert
				v-if="history.length > 0 && showHistoryAlert"
				class="mb-4 p-3"
				variant="warning"
				alternate
				:data-test-automation-id="getAutomationId('claim-history-alert-statement')"
			>
				<p class="lato font-17 font-light m-0">{{ t('statementAlert') }}</p>
			</BaseAlert>
			<HistoryTable
				v-for="(entry, index) in history"
				:key="index"
				class="mt-4"
				:top-bar-items="topBarItems(entry.participantInfo)"
				:history-fields="historyFields"
				:history-headers="historyHeaders()"
				:history="entry.claimServices"
				show-totals
				:show-grand-totals="index === history.length - 1"
				:totals="[
					{ key: 'submittedAmount', value: entry.totalSubmittedAmount },
					{ key: 'paidAmount', value: entry.totalPaidAmount }
				]"
				:grand-totals="[
					{ key: 'submittedAmount', value: grandTotalSubmitted },
					{ key: 'paidAmount', value: grandTotalPaid }
				]"
			/>
			<BaseButton
				v-if="history.length > 0"
				class="mt-2 mb-4"
				:label="t('downloadButton')"
				variant="primary"
				:icon="['fal', 'print']"
				icon-position="left"
				:automation-id="getAutomationId('claim-history-download-button')"
				@click="downloadPDF()"
			/>
			<div v-else>
				<h2 class="mt-4 font-21">
					{{ initialSearch ? t('noRecent.title') : t('noResults.title') }}
				</h2>
				<p class="font-21">
					{{ initialSearch ? t('noRecent.description') : t('noResults.description') }}
				</p>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, watch, computed } from 'vue';
import store from '@/store';
import { format, subMonths, setDate, isBefore, subYears, endOfYear, parseISO } from 'date-fns';
import { enCA, frCA } from 'date-fns/esm/locale';
// components
import BaseCollapse from '@/components/common/base/BaseCollapse';
import ClaimPaymentHistoryFilter from '@/components/history/ClaimPaymentHistoryFilter';
import HistoryTable from '@/components/history/HistoryTable.vue';
import BaseAlert from '@/components/common/alert/BaseAlert.vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
// models
import Dependent from '@/models/Dependent.js';
import Benefit from '@/models/coverage/Benefits';
import History from '@/models/claims/History';
// composables
import { useI18n } from '@/composables/useI18n';
import { useBreadcrumbs } from '@/composables/useBreadcrumbs';
import { useComponentId } from '@/composables/useComponentId';
// routes
import { DASHBOARD } from '@/constants/Routes';
// utils
import { downloadBlob } from '@/utils/commonUtils';

const { t } = useI18n();
const root = getCurrentInstance().proxy.$root;
const { getAutomationId } = useComponentId();

// static
const { email, apiToken } = sessionStorage;
const historyFields = [
	'dateProcessed',
	'serviceInfo',
	'serviceDate',
	'submittedAmount',
	'paidAmount',
	'hasPaymentDetails',
	'claimId'
];

// refs
const pageLoaded = ref(false);
const searchCollapsed = ref(false);
const participants = ref([]);
const benefits = ref([]);
const history = ref(null);
const searchData = ref(null);
const results = ref(null);
const initialSearch = ref(true);

watch(
	() => root.$i18n.locale,
	async () => {
		const hasBenefits = await Benefit.hasBenefits(email, root.$i18n.locale);

		if (!hasBenefits) {
			store.dispatch('updateLoading', true);
			pageLoaded.value = false;
		}

		benefits.value = makeBenefitArray(await Benefit.getBenefits(email, root.$i18n.locale));
		if (searchData.value) await handleSearch(searchData.value, initialSearch.value);

		store.dispatch('updateLoading', false);
		pageLoaded.value = true;
	}
);

// computed
const grandTotalSubmitted = computed(() =>
	history.value.reduce((total, item) => total + item.totalSubmittedAmount, 0)
);
const grandTotalPaid = computed(() =>
	history.value.reduce((total, item) => total + item.totalPaidAmount, 0)
);
// will return false if before Jan 1, 2 years ago.
const showHistoryAlert = computed(() =>
	isBefore(parseISO(searchData.value.fromDate), endOfYear(subYears(new Date(), 3)))
);
const activeParticipants = computed(() => {
	return participants.value.filter((p) => p.active);
});

// methods
function historyHeaders() {
	return [
		{ key: 'dateProcessed', label: t('table.dateProcessed') },
		{ key: 'serviceInfo', label: t('table.benefitDesc') },
		{ key: 'serviceDate', label: t('table.serviceDate') },
		{ key: 'submittedAmount', label: t('table.submittedAmount') },
		{ key: 'paidAmount', label: t('table.blueCrossPaid') },
		{ key: 'hasPaymentDetails', label: t('table.statement') }
	];
}
function changeCollapse() {
	searchCollapsed.value = !searchCollapsed.value;
}
function makeBenefitArray(benefits) {
	const arrBenefits = Object.entries(benefits)
		.map(([value, text]) => ({ value, text }))
		.sort((a, b) => a.text.localeCompare(b.text));
	arrBenefits.unshift({ value: 'ALL', text: t('all') });
	return arrBenefits;
}
function topBarItems(item) {
	const fullName = `${item.firstName} ${item.lastName}`;
	return [fullName, `${t('dob')} ${formatDate(item.dateOfBirth)}`];
}
function formatDate(date) {
	return format(new Date(parseISO(date)), 'dd MMM yyyy', {
		locale: root.$i18n.locale === 'fr' ? frCA : enCA
	});
}
async function handleSearch(data, pageLoad = false) {
	const benefitCode = data.selectedBenefit;
	const participantId =
		data.selectedParticipant.participantId === 'all'
			? participants.value.map((p) => p.participantId)
			: [data.selectedParticipant.participantId];

	store.dispatch('updateLoading', true);

	const hasHistory = await History.hasHistory(
		email,
		participantId,
		root.$i18n.locale,
		data.searchType,
		participantId,
		data.fromDate,
		data.toDate,
		benefitCode
	);

	history.value = await History.getHistory(
		email,
		apiToken,
		root.$i18n.locale,
		data.searchType,
		participantId,
		data.fromDate,
		data.toDate,
		benefitCode
	);
	searchData.value = data;
	store.dispatch('historyUpdate', data);
	// set to false after initial search to display no result found vs no recent claims
	initialSearch.value = pageLoad;

	// simulates load if cached data exists. This helps UI when filtering data and searching cahced data.
	setTimeout(
		async () => {
			if (!data.pageLoad) results.value.scrollIntoView({ behavior: 'smooth' });
			store.dispatch('updateLoading', false);
		},
		hasHistory ? 500 : 0
	);
}
async function downloadPDF() {
	store.dispatch('updateLoading', true);

	const data = searchData.value;
	const benefitCode = data.selectedBenefit;
	const participantId =
		data.selectedParticipant.participantId === 'all'
			? participants.value.map((p) => p.participantId)
			: [data.selectedParticipant.participantId];

	await History.getHistoryPDF(
		email,
		apiToken,
		root.$i18n.locale,
		data.searchType,
		participantId,
		data.fromDate,
		data.toDate,
		benefitCode
	).then((myBlob) => {
		downloadBlob(
			myBlob,
			root.$i18n.locale === 'fr'
				? `historique-des-demandes-du-${data.fromDate}-au-${data.toDate}.pdf`
				: `claims-history-${data.fromDate}-to-${data.toDate}.pdf`
		);
	});

	store.dispatch('updateLoading', false);
}

// lifecycle hooks
onMounted(async () => {
	try {
		const hasParticipants = await Dependent.hasDependents(email);
		const hasBenefits = await Benefit.hasBenefits(email, root.$i18n.locale);

		if (!hasParticipants || !hasBenefits) {
			store.dispatch('updateLoading', true);
			pageLoaded.value = false;
		}

		participants.value = (
			await Dependent.getDependents(email, apiToken, root.$i18n.locale, false)
		).data;
		benefits.value = makeBenefitArray(await Benefit.getBenefits(email, root.$i18n.locale));

		if (store.state.previousRoute.name === 'history/details' && store.state.history) {
			searchData.value = store.state.history;
		} else {
			searchData.value = {
				searchType: 'processed',
				fromDate: format(subMonths(setDate(new Date(), 1), 6), 'yyyy-MM-dd'),
				toDate: format(new Date(), 'yyyy-MM-dd'),
				selectedParticipant: {
					participantId: 'all'
				},
				selectedBenefit: 'ALL',
				pageLoad: true
			};
		}

		await handleSearch(searchData.value, true);

		useBreadcrumbs(
			[
				{ text: t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: t('breadcrumb.claimHistory', 'en') }
			],
			[
				{ text: t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: t('breadcrumb.claimHistory', 'fr') }
			]
		);

		pageLoaded.value = true;
		store.dispatch('updateLoading', false);
	} catch {
		store.dispatch('error');
	}
});
</script>

<style lang="scss" scoped>
.claim-history-filter {
	max-width: 825px;
}
</style>

<i18n lang="json">
{
	"en": {
		"breadcrumb": {
			"home": "Home",
			"claimHistory": "Claims History"
		},
		"search": "Search your claims",
		"all": "All",
		"table": {
			"dateProcessed": "Date Processed",
			"benefitDesc": "Benefit Description",
			"serviceDate": "Service Date",
			"submittedAmount": "Submitted Amount",
			"blueCrossPaid": "Blue Cross Paid",
			"statement": "Statement"
		},
		"title": "Claims History",
		"description": "Review or search for claims that you or anyone on your plan submitted. Statements are available for two years after the claim is processed, plus the rest of the following calendar year. Older claims may not appear in search.",
		"claimAlert": {
			"title": "Don't see a claim you've recently submitted?",
			"description": "If you've submitted a claim to us online or mailed in a claim and you don't see it here, our claims analysts may not have had a chance to review it just yet."
		},
		"dob": "Date of Birth:",
		"statementAlert": "Note: Statements are available for 24 months from the date of processing, plus the rest of the following calendar year. Older claims may not appear in search.",
		"downloadButton": "Download",
		"noResults": {
			"title": "No results found",
			"description": "We couldn't find anything matching your request. Please try a new search."
		},
		"noRecent": {
			"title": "No recent claims",
			"description": "We don't have any claims on file from the last 6 months. You can search to find older claims."
		}
	},
	"fr": {
		"breadcrumb": {
			"home": "Accueil",
			"claimHistory": "Historique des demandes de règlement"
		},
		"search": "Rechercher vos demandes de règlement",
		"all": "Tout",
		"table": {
			"dateProcessed": "Date de traitement",
			"benefitDesc": "Description de la garantie",
			"serviceDate": "Date du service",
			"submittedAmount": "Montant soumis",
			"blueCrossPaid": "Croix Bleue a payé",
			"statement": "Relevé"
		},
		"title": "Historique des demandes de règlement",
		"description": "Examinez ou recherchez les demandes de règlement que vous ou une autre personne couverte par votre régime avez soumises. Les relevés sont disponibles pendant deux ans après le traitement de la demande, en plus le reste de l'année civile suivante. Les demandes de règlement plus anciennes pourraient ne pas s'afficher dans les résultats de recherche.",
		"claimAlert": {
			"title": "Vous ne voyez pas une demande de règlement que vous avez récemment soumise?",
			"description": "Si vous nous avez soumis une demande de règlement en ligne ou si vous l'avez envoyée par la poste et que vous ne la voyez pas ici, il se peut que nos analystes des demandes de règlement n'aient pas encore eu l'occasion de l'examiner."
		},
		"dob": "Date de naissance:",
		"statementAlert": "Remarque : La plupart des relevés sont conservés pour une période de 24 mois, et pour le reste de l'année civile suivante.",
		"downloadButton": "Télécharger",
		"noResults": {
			"title": "Aucun résultat trouvé",
			"description": "Nous n'avons rien trouvé qui corresponde à votre demande. Veuillez effectuer une nouvelle recherche."
		},
		"noRecent": {
			"title": "Aucune demande de règlement récente",
			"description": "Nos dossiers indiquent qu'aucune demande de règlement n'a été soumise au cours des six derniers mois. Vous pouvez faire une recherche pour trouver des demandes de règlement plus anciennes."
		}
	}
}
</i18n>
